'use client'

import { SnackbarProvider, closeSnackbar } from 'notistack'
import type { PropsWithChildren } from 'react'

export const SnackProvider = ({ children }: PropsWithChildren) => {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      autoHideDuration={7000}
      action={(snackbarId) => (
        <button onClick={() => closeSnackbar(snackbarId)}>Dismiss</button>
      )}
    >
      {children}
    </SnackbarProvider>
  )
}
